


























































































































































import {Component, Vue, Watch} from "vue-property-decorator"
import CartModule from "@/store/CartModule"
import {getModule} from "vuex-module-decorators"
import Product from "@/models/Product"
import CategoryTreeviewComponent from "@/components/CategoryTreeviewComponent.vue"
import ProductService from "@/services/ProductService"
import ConstantTool from "@/services/tool/ConstantTool"
import ProductCategory from "@/models/ProductCategory"
import ProductCategoryService from "@/services/ProductCategoryService"
import LangModule from "@/store/LangModule"
import CartTool from "@/services/tool/CartTool"
import LoginService from "@/services/LoginService"
import SnackbarModule from "@/store/SnackbarModule"
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule"

@Component({ components: { CategoryTreeviewComponent } })
export default class ProductView extends Vue {

	cartModule: CartModule = getModule(CartModule)
	categoryTreeviewModule = getModule(CategoryTreeviewModule)
	get productCategories() { return this.categoryTreeviewModule.productCategories }

	mainImageNumber: number = 1
  BASE_URL = ConstantTool.BASE_URL
	product: Product = new Product
	amount: number = 0
	lang: any = getModule(LangModule).lang
	loading: boolean = false

	breadcumbs: any[] = [
		{
			text: this.lang.product,
			disabled: false,
			href: '/products',
		}
	]

  get isLoged(): boolean {
	return LoginService.isLoged()
  }
	
	created() {
		this.refresh()
	}

	downloadDocument() {
		let link = document.createElement("a")
		link.href = this.product.document!!.url!!
		link.target = "_blank"
		link.download = "filename"
		link.click();
	}

	bruh() {
		// @ts-ignore
		this.$refs.lazyVideo.resetView()
	}

	refresh() {
		ProductService.getProduct(this, parseFloat(this.$route.params.id))
	}

	redirectToSearch() {
		this.$router.push({ path: '/products' })
	}

	setImageIndex(val: number) {
		this.mainImageNumber = 0
		setTimeout(() => {this.mainImageNumber = val}, 500)
	}

	getTaxBase(): number { return Number(this.product!.rates![0].price) * Number(this.product.quantity) }

	addProduct() {
		if (this.isLoged) {
			CartTool.addProduct(this.cartModule, this.product)
			CartTool.refreshCart()
		} else {
			this.$router.push('/login')
		}
  }

	addFavourite() {
	if (this.isLoged) {
	  getModule(SnackbarModule).makeToast(this.lang.favouriteAdded)
	} else {
	  this.$router.push("/login")
	}
  }

	@Watch('product')
	onProductCalled() {
		this.product.quantity = 1
		if (this.product.productCategories!.length != 0) {
			this.breadcumbs.push({
				text: this.product.productCategories![0].name,
				disabled: false,
				href: '/products',
				parent: this.product.productCategories![0].parent
			})
		} else {
			this.breadcumbs.push({
				text: 'INCATEGORIZADO',
				disabled: false,
				href: '/products',
			})
		}
	}
}
